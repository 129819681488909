import React from 'react'

/* Texts */
import texts from '../../constants/texts'

export default ({ locale }) => {
  return (
    <section className='bg--light padding' style={{ height: '100%' }}>
      <div className='container'>
        <div className='row'>
          <div className='col-sm-9 col-md-7'>
            <h1>
              <b>{texts[locale].downloads.title}</b>
            </h1>
            {Object.keys(texts[locale].downloads.documents).map((doc, i) => (
              <React.Fragment key={i}>
                <p className='lead' style={{ margin: '30px 0 5px 0' }}>
                  {texts[locale].downloads.documents[doc].title}
                </p>
                {texts[locale].downloads.documents[doc].files.map((file, j) => (
                  <a
                    key={file.file_name}
                    className='btn btn--sm btn--primary type--uppercase'
                    style={{ margin: '0 10px 0 0' }}
                    href={require(`../../pdf/${file.file_name}`)}
                    download
                  >
                    <span className='btn__text'>{file.title}</span>
                  </a>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
